@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --brand-main: 54 190 145;

    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply text-foreground;
  }
}

.font-mono {
  @apply tabular-nums;
}

@font-face {
  font-family: "Walsheim";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/fonts/GT-Walsheim-Pro-Regular.eot) format("eot"), url(/fonts/GT-Walsheim-Pro-Regular.otf) format("otf"),
    url(/fonts/GT-Walsheim-Pro-Regular.ttf) format("ttf"), url(/fonts/GT-Walsheim-Pro-Regular.woff) format("woff"),
    url(/fonts/GT-Walsheim-Pro-Regular.woff2) format("woff2");
}

@font-face {
  font-family: "Walsheim";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/fonts/GT-Walsheim-Pro-Medium.eot) format("eot"), url(/fonts/GT-Walsheim-Pro-Medium.otf) format("otf"),
    url(/fonts/GT-Walsheim-Pro-Medium.ttf) format("ttf"), url(/fonts/GT-Walsheim-Pro-Medium.woff) format("woff"),
    url(/fonts/GT-Walsheim-Pro-Medium.woff2) format("woff2");
}

@font-face {
  font-family: "Walsheim";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url(/fonts/GT-Walsheim-Pro-Regular-Oblique.eot) format("eot"), url(/fonts/GT-Walsheim-Pro-Regular-Oblique.otf)
    format("otf"), url(/fonts/GT-Walsheim-Pro-Regular-Oblique.ttf) format("ttf"),
    url(/fonts/GT-Walsheim-Pro-Regular-Oblique.woff) format("woff"), url(/fonts/GT-Walsheim-Pro-Regular-Oblique.woff2)
    format("woff2");
}

@font-face {
  font-family: "Walsheim";
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: url(/fonts/GT-Walsheim-Pro-Medium-Oblique.eot) format("eot"), url(/fonts/GT-Walsheim-Pro-Medium-Oblique.otf)
    format("otf"), url(/fonts/GT-Walsheim-Pro-Medium-Oblique.ttf) format("ttf"),
    url(/fonts/GT-Walsheim-Pro-Medium-Oblique.woff) format("woff"), url(/fonts/GT-Walsheim-Pro-Medium-Oblique.woff2)
    format("woff2");
}
